<template>
  <section class="house-in-allotment">
    <section class="hero">
      <div class="hero-container">
        <h1>Pourquoi faire construire sa maison en lotissement ?</h1>
      </div>
    </section>
    <div class="hero-img">
      <img src="@/assets/img/pages/house-in-allotment-1.jpg" alt="Borner son terrain" />
    </div>
    <article class="grid-container article">
      <div class="article-title fit-content">
        <h3>
          Entre l’achat d’une parcelle isolée ou d’un terrain loti, beaucoup de candidats à la
          construction optent pour la seconde formule : ce type d’opération est en efet réputé plus
          simple, rapide et sécurisant qu’en secteur diffus. Explication.
        </h3>
      </div>
      <div class="article-card">
        <card-type-img>
          <template v-slot:content>
            <p>
              Associé au phénomène d’étalement urbain qui s’est amorcé en France dans les années
              1960, le lotissement, symbole visuel de la France pavillonnaire, est devenu l’une des
              principales voies d’accession à la propriété pour ces ménages – toujours plus nombreux
              – qui souhaitent s’établir en dehors des grandes villes, là où les prix sont plus
              attractifs.
            </p>
            <p>
              Aujourd’hui, un français sur quatre réside dans ces « couronnes » périphériques
              composées à 90% de logements individuels nés, pour la moitié d’entre eux, d’une
              opération de lotissement (source : Xerf). Pourquoi ce choix massif ?
            </p>
          </template>
          <template v-slot:img>
            <img
              src="@/assets/img/pages/house-in-allotment-2.jpg"
              alt="Déterminer les limites légales d'un terrain à constuire"
            />
          </template>
        </card-type-img>
      </div>
      <div class="article-simplified-procedures fit-content">
        <h2>Des procédures simpliées</h2>
        <div class="article-simplified-procedures-img">
          <img
            src="@/assets/img/pages/house-in-allotment-3.jpg"
            alt="Maîtriser les coûts de construction"
          />
        </div>
        <div class="article-simplified-procedures-text">
          <p>
            Commençons d’abord par bien défnir les choses, au moins sur le plan juridique : le Code
            de l’Urbanisme défnit ce type d’aménagement immobilier comme « la division en propriété
            ou en jouissance d’une ou plusieurs unités foncières contigües destinées à être bâties »
            et commercialisés sous forme de lots (article L442-1). Sa réalisation implique en
            général la concours de plusieurs professionnels qui, chacun dans leurs domaines
            d’expertise (architecture, paysagisme, géométrisme, environnement), s’emploient à défnir
            un cahier des charges en accord avec les collectivités territoriales et conformes aux
            règles d’urbanisme qu’elles ont établies localement.
          </p>
          <p>
            Ce respect des normes et la procédure très stricte qui encadrent l’implantation d’un
            lotissement déleste l’acquéreur de nombreuses contraintes administratives : y
            <router-link to="/projet-construction-maison/ou-faire-construire-sa-maison">faire construire sa maison</router-link>
            ne nécessite pas d’obtenir un certifcat d’urbanisme dans la mesure
            où cette démarche est eectuée en amont (dès le montage du programme) par l’aménageur. De
            même : les terrains à vendre étant légalement reconnus comme du foncier destiné à
            accueillir du bâti, les permis de construire sont délivrés de droit et rapidement, sous
            réserve toutefois que le projet soit compatible avec le règlement du lotissement et les
            plans locaux d’urbanisme ou d’occupation des sols applicables au secteur.
          </p>
          <p>
            Autres avantages : les parcelles livrées sont en quelque sorte « prêtes à l’emploi ».
            L’ensemble des opérations de raccordement aux réseaux de gaz, d’électricité, d’eau et de
            télécommunications sont réalisées, comme les travaux d’assainissement avec
            l’installation du tout-à-l’égout. Une fois le terrain acquis, il ne reste donc au
            nouveau propriétaire qu’à choisir l’emplacement de sa future maison et d’en défnir la
            confguration.
          </p>
          <p>
            Sécurité supplémentaire pour l’acheteur d’un bien en lotissement : la loi oblige au
            promoteur d’annexer à sa promesse de vente des informations relatives au terrain
            (descriptif détaillé du bien, superfcie précise, certifcat de bornage, hypothèques et
            servitudes légales éventuelles), ainsi qu’aux modalités de l’opération (délai de
            livraison, prix, date de validité de l’avant-contrat, montant des honoraires à verser
            aux professionnels chargés de la transaction).
          </p>
          <p>
            Rappelons que la loi sur l’orientation des mobilités (24 décembre 2019) impose qu’à
            partir du 1er juin 2020 le vendeur d’un bien immobilier signale à l’acquéreur les
            risques de nuisances sonores induites par la présence d’aérodromes.
          </p>
          <p>
            Dernière précision : le signataire d’une promesse de vente portant sur un bien « loti »
            dispose d’un délai légal de rétractation de dix jours, un droit qui n’existe pas pour
            les achats d’un terrain isolé.
          </p>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import CardTypeImg from '@/components/general/CardTypeImg.vue';

export default {
  name: 'house-in-allotment',
  components: {
    CardTypeImg,
  },
};
</script>

<style lang="sass">
.house-in-allotment
  background-color: $medium-bg
  padding-bottom: 6rem
  .hero
    background-color: darken($primary, 5%)
    height: 35rem
    display: flex
    flex-direction: column
    align-items: center
    &-container
      margin-top: 8rem
      width: 60rem
      h1
        color: $white
        text-align: center
    &-img
      display: flex
      flex-direction: row
      justify-content: center
      img
        border-radius: $global-border-radius
        margin-top: -15rem
  .article
    &-title
      display: flex
      justify-content: center
      margin: 4.5rem auto 0 auto
      h3
        text-align: center
    &-card
      margin: 4.5rem auto 0 auto
      p:not(:last-child)
        margin-bottom: 1rem
      a
        font-weight: normal !important
    &-simplified-procedures
      margin: 4.5rem auto 0 auto
      h2
        text-align: center
        color: $primary
      &-img
        margin-top: 4.5rem
        img
          object-fit: cover
          width: 100%
          border-radius: $global-border-radius
      &-text
        margin-top: 3rem
        p:not(:last-child)
          margin-bottom: 1rem
        a
          @include hyperlink
          text-decoration: none
          font-size: inherit
          font-weight: normal
  .fit-content
    width: 90%;
    max-width: 950px;
  @media (max-width: 768px)
    .hero
      height: 20rem
      &-container
        margin-top: 6rem
        width: 90%
      &-img
        img
          object-fit: cover
          width: 90%
          margin-top: -5rem
    .article
      &-title
        margin: 2.5rem auto 0 auto
      &-card
        margin: 2.5rem auto 0 auto
      &-simplified-procedures
        margin: 3rem auto 0 auto
        &-img
          margin-top: 2.5rem
        &-text
          margin-top: 1.5rem
</style>
